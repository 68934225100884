import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import routes from '@/router/routes'
import { store } from '@/store/index.js'
import { checkIfTokenNeedsRefresh } from '@/utils/utils.js'
import { checkForUpdates } from '@/utils/updates.js'
import * as types from '@/store/mutation-types'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes],
})

router.beforeEach((to, from, next) => {
  checkForUpdates()
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const isTokenSet = store.getters.isTokenSet

  // Sécurisation des routes selon les droits utilisateur
  const route = router.getRoutes().find(o => o.path === to.path)
  if (typeof route.props.default !== 'undefined') {
    const module = route.props.default.module
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && !user.cf_1832.match(new RegExp(module, 'i'))) {
      return next('/')
    }
  }

  if (requiresAuth && !isTokenSet) {
    return next('/login' + (to.fullPath !== '/' ? '?redirect=' + to.name + '&_id=' + to.query._id : ''))
  }
  checkIfTokenNeedsRefresh()
  store.commit(types.SUCCESS, null)
  store.commit(types.ERROR, null)

  return next()
})

export default router
