import axios from 'axios'

export default {
  getAccounts (params) {
    return axios.get('Dependencies/Accounts', {
      params,
    })
  },
  getContacts (params) {
    return axios.get('Dependencies/Contacts', {
      params,
    })
  },
  getPoles (params) {
    return axios.get('Dependencies/Poles', {
      params,
    })
  },
  getPointages (params) {
    return axios.get('Dependencies/Pointages', {
      params,
    })
  },
  getParcLogiciel (params) {
    return axios.get('Dependencies/ParcLogiciel', {
      params,
    })
  },
  getConfiguration (params) {
    return axios.get('Configuration', {
      params,
    })
  },
}
