export default [
  {
    path: '/home',
    name: 'home',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/components/Home.vue'),
  },
]
