import * as types from '@/store/mutation-types'
import api from '@/services/api/dependencies'
import { handleError } from '@/utils/utils.js'

const getters = {
  accounts: (state) => state.accounts,
  totalAccounts: (state) => state.totalAccounts,
  contacts: (state) => state.contacts,
  totalContacts: (state) => state.totalContacts,
  poles: (state) => state.poles,
  totalPoles: (state) => state.totalPoles,
  pointages: (state) => state.pointages,
  totalPointages: (state) => state.totalPointages,
  parclogiciel: (state) => state.parclogiciel,
  totalParclogiciel: (state) => state.totalParclogiciel,
  configuration: (state) => state.configuration,
  totalConfiguration: (state) => state.totalConfiguration,
}

const actions = {
  getAccounts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getAccounts(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.ACCOUNTS, response.data.records)
            commit(types.TOTAL_ACCOUNTS, parseInt(response.data.count))
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getContacts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getContacts(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.CONTACTS, response.data.records)
            commit(types.TOTAL_CONTACTS, parseInt(response.data.count))
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getPoles ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getPoles(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.POLES, response.data.records)
            commit(types.TOTAL_POLES, parseInt(response.data.count))
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getPointages ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getPointages(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.POINTAGES, response.data.records)
            commit(types.TOTAL_POINTAGES, parseInt(response.data.count))
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getParcLogiciel ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getParcLogiciel(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.PARCLOGICIEL, response.data.records)
            commit(types.TOTAL_PARCLOGICIEL, parseInt(response.data.count))
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getConfiguration ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getConfiguration(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.CONFIGURATION, response.data.records)
            commit(types.TOTAL_CONFIGURATION, parseInt(response.data.count))
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
}

const mutations = {
  [types.ACCOUNTS] (state, records) {
    state.accounts = records
  },
  [types.TOTAL_ACCOUNTS] (state, value) {
    state.totalAccounts = value
  },
  [types.CONTACTS] (state, records) {
    state.contacts = records
  },
  [types.TOTAL_CONTACTS] (state, value) {
    state.totalContacts = value
  },
  [types.POLES] (state, records) {
    state.poles = records
  },
  [types.TOTAL_POLES] (state, value) {
    state.totalPoles = value
  },
  [types.POINTAGES] (state, records) {
    state.pointages = records
  },
  [types.TOTAL_POINTAGES] (state, value) {
    state.totalPointages = value
  },
  [types.PARCLOGICIEL] (state, records) {
    state.parclogiciel = records
  },
  [types.TOTAL_PARCLOGICIEL] (state, value) {
    state.totalParclogiciel = value
  },
  [types.CONFIGURATION] (state, records) {
    state.configuration = records
  },
  [types.TOTAL_CONFIGURATION] (state, value) {
    state.totalConfiguration = value
  },
}

const state = {
  accounts: [],
  totalAccounts: 0,
  contacts: [],
  totalContacts: 0,
  poles: [],
  totalPoles: 0,
  parclogiciel: [],
  totalParclogiciel: 0,
  configuration: [],
  totalConfiguration: 0,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
