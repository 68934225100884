import * as types from '@/store/mutation-types'
import api from '@/services/api/schema'
import { handleError } from '@/utils/utils.js'

const getters = {
  schema: (state) => state.schema,
}

const actions = {
  getSchema ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getSchema(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.SCHEMA, response.data.describe)
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
}

const mutations = {
  [types.SCHEMA] (state, record) {
    state.schema = record
  },
}

const state = {
  schema: {},
}

export default {
  state,
  getters,
  actions,
  mutations,
}
