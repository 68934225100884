export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/Login.vue'),
  },
  // {
  //   path: '/signup',
  //   name: 'signup',
  //   component: () => import('@/components/SignUp.vue'),
  // },
  // {
  //   path: '/verify/:id',
  //   name: 'verify',
  //   component: () => import('@/components/Verify.vue'),
  // },
  {
    path: '/forgot',
    name: 'forgotPassword',
    component: () => import('@/components/ForgotPassword.vue'),
  },
]
