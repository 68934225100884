<template>
  <v-col
    class="text-center mt-5 mb-3"
    cols="12"
  >
    <h1 class="text-h3 font-weight-bold">
      {{ title }}
    </h1>
  </v-col>
</template>

<script>
  export default {
    name: 'Heading',
    props: {
      title: String,
    },
  }
</script>
