import axios from 'axios'
import router from '@/router'

export default {
  getRecords (params) {
    const module = router.getRoutes().find(o => o.path === router.currentRoute.path).props.default.module
    return axios.get('/Get/' + module, {
      params,
    })
  },
  editRecord (id, payload) {
    const module = router.getRoutes().find(o => o.path === router.currentRoute.path).props.default.module
    return axios.patch('/Update/' + module + `/${id}`, payload)
  },
  saveRecord (payload) {
    const module = router.getRoutes().find(o => o.path === router.currentRoute.path).props.default.module
    return axios.post('/Create/' + module + '/', payload)
  },
  saveComment (payload) {
    return axios.post('/Create/ModComments/', payload)
  },
  deleteRecord (id) {
    const module = router.getRoutes().find(o => o.path === router.currentRoute.path).props.default.module
    return axios.delete('/Delete/' + module + `/${id}`)
  },
  getAttachments (id) {
    return axios.get('/Get/Attachments' + `/${id}`)
  },
  downloadAttachment (obj) {
    return axios.get('/Get/Attachment' + `/${obj.id}` + '?attachment=' + obj.attId)
  },
  reopenTicket (id) {
    return axios.get('/ReOpen/HelpDesk' + `/${id}`)
  },
}
